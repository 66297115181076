.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
  }
  .card {
    flex: 0 0 calc(33% - 20px);
    max-width: calc(33% - 20px);
    width: 100%;
    background: hsl(var(--accent) );
    background: #0000001c;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    text-align: center;
    border: 2px solid transparent;
    transition: 0.3s;
    overflow: hidden;
  }
  /* CONTORNO ILLUMINATO BARRICK NELLA HOME QUANDO CI PASSI SOPRA CON IL MOUSE */
  .card:hover {
    border-color: #000; //#418E27
  }
 
  .card h3 {
    font-size: 35px;
    //color: hsl(var(--txt));
    color: #000;
    margin-bottom: 15px;
  }
  .card p{
    font-size: 20px;
  }
  .card .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 120px;
    width: 100%;
    background: #3b7dab;
    border: none;
    outline: none;
    font-size: 20px;
    border-radius: 10px;
    height: 40px;
    margin-top: 20px;
    color: #000000;
    cursor: pointer;
    transition: 0.3s;
    border: 1px solid #bdbec5;
  }
  .card .btn:hover {
    color: #bdbec5;
    background: transparent;
  }
  
  @media (max-width: 969px) {
    section{
      padding: 30px 0px;
    }
    section h1 {
      font-size: 40px;
    }
    .card {
      flex: 0 0 calc(50% - 10px);
      max-width: calc(50% - 10px);
    }
  }
  @media (max-width: 767px) {
    
    .card {
      flex: 0 0 calc(100% - 20px);
      max-width: calc(100% - 20px);
      padding: 20px;
    }
    .card h3 {
      font-size: 18px;
      color: hsl(var(--txt));
      /*color: #bdbec5;*/
      margin-bottom: 15px;
    }
  }

  .image-container{
    overflow: hidden;
    
    width: 100px;
    height: auto;

    display: flex;
    margin: 0 auto;
    justify-content: center;
    border-radius: 10% !important;
  }

  @media (max-width: 767px) {
    .image-container{
      overflow: hidden;

      width: 100px;
      height: auto;

      display: flex;
      margin: 0 auto;
      justify-content: center;
      border-radius: 10% !important;
    }
    
  }

  .img-in-container{
        
        max-width: inherit;
        max-height: inherit;
        height: inherit;
        width: inherit;
        object-fit: cover;
  }

