.coolLink{
    position: relative;
    display: grid;
    place-items: center;
    overflow: hidden;
    &:before{
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background: currentColor;
        transform: scale3d(0, 1, 1);
        transform-origin: right;
        transition: .3s cubic-bezier(.5,0.5,.3,1);
    }
    &:after{
        content: attr(data-text);
        position:absolute;
        top: 0;
        height: 100%;
        transform: translate3d(150%,0,0);
        transition: .3s cubic-bezier(.5,0.5,.3,1);
    }
    & span{
        transition: .3s cubic-bezier(.5,0.5,.3,1);
    }
    &:hover:before{
        transform: scale3d(1, 1, 1);
        transform-origin: left;
    }
    &:hover::after{
        transform: translate3d(0, 0, 0);
    }
    &:hover span{
        transform: translate3d(-150%, 0, 0);
    }
}

.coolButton{
    position:relative;
    border:none;
    cursor:pointer;
    width: 175px;
    height: 120px;
    background: none;
    color: #fff;
    &:before,
    &:after{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    &:before{
        content:"";
        background: #ED7D15;
        clip-path: path("M154.5,88.5 C131,113.5 62.5,110 30,89.5 C-2.5,69 -3.5,42 4.5,25.5 C12.5,9 33.5,-6 85,3.5 C136.5,13 178,63.5 154.5,88.5 Z");
        transition: clip-path 0.5s cubic-bezier(0.585,2.5,0.645,0.555);
    }
    &:hover:before{
        clip-path: path("M143,77 C117,96 74,100.5 45.5,91.5 C17,82.5 -10.5,57 5.5,31.5 C21.5,6 79,-5.5 130.5,4 C182,13.5 169,58 143,77 Z");
    }
    & span{
        mix-blend-mode: luminosity;
        font-weight: 500;
    }
    &:after{
        content: "";
        height: 85%;
        width: 98%;
        top: 6px;
        border: 1px solid #ED7D15;
        border-radius: 58% 42% 55% 45% / 56% 45% 55% 44%; 
        transform: rotate(-20deg);
        transition: transform .5s cubic-bezier(0.585,2.5,0.645,0.555);
    }
    &:hover:after{
        transform: translate3d(0, -5px, 0)
    }
    @media (max-width:800px) {
        & {
            transform: scale3d(.7,.7,.7);
        }
    }
}

.coloredIcon{
    color: #fff;
    font-size: 35px;
}
.coolCircleEyeButton{
    position: relative;
    display: inline-block;
    .textcircle{
        display: block;
        width: 250px;
        animation: rotate 7s linear infinite;
        text{
            font-size: 32px;
            text-transform: uppercase;
            fill: #000;
        }
    }
    @keyframes rotate {
        to{
            transform: rotate(-360deg);
        }
    }
    &:hover{
        text{
            font-weight: 650;
        }
        .eye__inner,.eye__iris,.eye__lashes-up{
            animation: hide 2s step-end infinite;
            @keyframes hide{
                0%{
                    opacity: 0;
                }
                10%{
                    opacity: 1;
                }
            }
        }
        .eye__lashes-down{
            animation: show 2s step-end infinite;
            @keyframes show{
                0%{
                    opacity: 1;
                }
                10%{
                    opacity: 0;
                }
            }
        }
    }
}
.eye{
    position: absolute;
    width: 60px;
    height: 60px;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    &__outer,&__inner,&__lashes-up,&__lashes-down{
        stroke: #000;
        fill: none;
        stroke-width: 1.5px;
    }
    &__lashes-down{
        opacity: 0;
    }
}