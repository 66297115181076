.projects{
    &__header{
        font-size: 60px;
        font-style: italic;
        padding-bottom: 10px;
    }
    p{
        font-size: 20px;
        background: rgba(0,0,0,0.1);
        border-radius: 5px;
    }
}

.project{
    &__header{
        display: grid;
        grid-template-columns: 1fr 3fr;
        gap: 1rem;
        font-family: sans-serif;
        span{
            border-top: 1px solid #000;
            padding: 1rem;
            font-size: 30px;
            text-align: center;
        }
    }
    &__infos{
        margin-top: 1rem;
        &--name{
            position: relative;
            font-size: 3vw;
            font-family: sans-serif;
            display: flex;
            align-items: center;
            font-weight: 400;
        }
    }
    &__img{
        position: relative;
        width: 100vw;
        height: 700px;
        img{
            border-radius: 5px;
            width: 60vw;
            height: 600px;
            object-fit: cover;
            position: absolute;
            right: 10vw;
            top: 3rem;
        }
    }
    &__links{
        padding-top: 3rem;
        button{
            &:before{
                background:#000;
            }
            &:after{
                display: none;
            }
        }
    }
    &__tags{
        position: absolute;
        top: 0;
        display: flex;
        flex-direction: row;
        gap: 1rem;
        color: blue;
        text-underline-position: under;
        text-decoration: underline;
    }
}

@media (max-width:1350px){
    .project__links{
        display: none;
    }
    .projects{
        padding: 3rem;
    }
    .project__img img{
        width: 74vw;
        left: 0rem;
    }
    .project__infos--name{
        font-size: 38px;
    }
    .project .coolCircleEyeButton{
        transform: translate(-14rem,-3rem);
    }
}
@media (max-width:740px){
    .projects{
        padding: 1rem;
    }
    .project__tags{
        flex-direction: row;
        top: 0;
        left: 0;
    }
    .project__infos--name{
        font-size: 35px;
        span{
            display: none;
        }
    }
    .project__img img{
        width: 85vw;
        right: 0;
    }
}
@media (max-width:440px) {
    .project .coolCircleEyeButton{
        transform: translate(0);
    }
    .project__tags{
        gap: 6px;
        font-size: 14px;
    }
    .project__header span{
        font-size: 25px;
    }
}