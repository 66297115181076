.landing{
    position: relative;
    width: 100%;
    height: 100vh;
    background: #fff;
    color: #000;
    overflow: hidden;
    //background-image: url("../images/shapes/67.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    .header{
        position: absolute;
        //top: 1rem;
        top:-100%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        &__wrap{
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 60vw;
        }
        &__left{
            display: flex;
            align-items: center;
            gap: 2rem;
            & li{
                font-size: 18px;
                font-weight: 700;
            }
        }
        @media (max-width: 1200px) {
            &__wrap{
                width: 100vw;
            }
        }
        @media (max-width: 650px) {
            &__sm{
                display: none;
            }
        }
        @media (max-width: 370px) {
            & {
                left: 36%;
            }
            &__wrap{
                gap: 20%;
            }
            .coolLink[data-text="Home"]{
                display: none;
            }
        }
    }
}
.socials{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 10rem;
    color: #fff;
    //bottom: -100%;
    //display: flex;
    display: none;
    align-items: center;
    gap:1rem;
    z-index: 2;
    background-color: #428E27;
    border-radius: 10px;
    h4{
        padding-left: 10px;
        font-size:25px;
    }
    .yticon{
        padding-right: 10px;
    }

    @media (min-width:670px) {
        & h4{
            font-size: 35px;
        }
        & h1{
            font-size: 55px;
        }
    }
    @media (max-height:700px) and (width >= 1000px){
        bottom: 5rem;
    }
    @media (min-width:1000px) {
        left: 23%;
        & h4{
            font-size: 50px;
        }
        & h1{
            font-size: 70px;
        }
    }
}
.scrollDown{
    position: absolute;
    //bottom: 3rem;
    bottom: -100%;
    left: 50%;
    transform: translateX(-50%);
    &__wheel{
        height: 42px;
        width: 24px;
        border-radius: 10px;
        border: 2px solid #000;
        &--inner{
            position: absolute;
            left: 10px;
            margin-top: 5px;
            width: 4px;
            height: 4px;
            background: #000;
            border-radius: 50%;
            animation: movingwheel .5s linear infinite;
            @keyframes movingwheel {
                0%{
                    top: 1px;
                }
                25%{
                    top: 2px;
                }
                50%{
                    top: 3px;
                }
                75%{
                    top: 2px;
                }
                100%{
                    top: 1px;
                }
            }
        }
    }
    .scrollDown__arrows{
        display: flex;
        flex-direction: column;
        align-items: center;
        .scrollDown__arrow{
            width: 15px;
            height: 15px;
            border-right: 2px solid #000;
            border-bottom: 2px solid #000;
            transform: rotate(45deg);
            animation: scrollAnim 1s infinite;
            animation-direction: alternate;
            &:nth-child(1){
                animation-delay: .1s;
            }
            &:nth-child(2){
                animation-delay: .2s;
            }
            &:nth-child(3){
                animation-delay: .3s;
            }
            @keyframes scrollAnim {
                0%{
                    opacity: 0;
                }
                50%{
                    opacity: .5;
                }
                100%{
                    opacity: 1;
                }
            }
        }
    }
}
.intro{
    position: absolute;
    top: 20%;
    left: 0;
    width: 100%;
    height: 60%;

    display: flex;
    justify-content: center;
    align-items: stretch;
    
    z-index: 2;
    color: #000;
    //font-family: 'Shantell Sans', sans-serif;
    & h4{
        font-size: 32px;
        //background: rgba(0,0,0,.25);
    }
    & h1{
        //background: rgba(0,0,0,.25);
        color: #418E27;
        font-size: 35px;
        width: 93%;
        
    }
    &__el{
        
        flex-grow: .25;
        margin: 10px;
    }
    &__img{
        display: none;
        max-width: 33%;

    }
    @media (max-width:740px) {
        
        &__img{
            display:none;
        }

    }
    
    @media (min-width:670px) {
        & h4{
            font-size: 40px;
        }
        & h1{
            font-size: 55px;
        }
    }
    @media (min-width:1200px) {
        & h4{
            font-size: 50px;
        }
        & h1{
            font-size: 70px;
        }
    }
}