//@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Shantell+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
// non posso importare direttamente i google fonts perchè fanno parte dei cookie marketing
// e necessitano di consenso dell'utente.
* {
    margin: 0;
    box-sizing: border-box;
    &:after,
    &:before{
        box-sizing: border-box;
    }
}
html,
body{
    overflow-x: hidden;
}
body{
    font-family: 'Poppins', sans-serif;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    height: 100vh;
}

p,
h1,
h2,
h3,
h4,
h5,
h6{
    overflow-wrap: break-word;
}

button,
input,
textarea,
select{
    font:inherit
}

img,
video,
canvas,
svg{
    max-width: 100%;
}

a{
    text-decoration: none;
    color: inherit;
}
ul{
    list-style: none;
}

/*------Variabili------*/
$green-color: #42ac25;
$light-green-color: #5cd839;
$dark-green-color: #297713;

$greenbuddy: #418E27;

$skills-bg: #d3b138;


/*--------Classi Predefinite----------*/
.section{
    padding: 1rem;
    overflow: hidden;
    //min-height: 100vh;
    padding-top: 2rem;
    @media (min-width: 650px) {
        padding: 5rem;
    }
    &__header{
        &-title{
            font-weight: 400;
            font-size: 17px;
            color:#666;
        }
        &-subtitle{
            font-size: 27px;
            font-weight: 700;
        }
    }
}

.blue-link{
    color: blue;
    text-underline-position: under;
    text-decoration: underline;
}
.logobuddy{
    position: absolute;
    width: 50px;
    @media (min-width:1200px) {
        top: 3rem;
        left: 6rem;
        width: 8vw;
    }
}
.dashed-line {
    border-top: 3px dashed rgba(0, 0, 0, 0.484);
    margin: 1rem;
    border-spacing: 0 10px;
  }